import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue';
import CookieConsent from '../components/CookieConsent.vue';
import CookiePolicy from '../components/CookiePolicy.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld
    },
    {
      path: '/cookie-consent',
      name: 'CookieConsent',
      component: CookieConsent
    },
    {
      path: '/cookie-policy',
      name: 'CookiePolicy',
      component: CookiePolicy
    }
  ]
});
