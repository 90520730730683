<template>
  <v-app>
    <v-main>
      <div class="home">
        <v-app-bar app color="grey darken-4" dark fixed max-width="100%">
          <v-toolbar-title class="px-0">
            <v-img src="@/assets/img/ev-key_logo-white.png" alt="Logo" height="50px" width="150px" class="logo-img"
              eager lazy-src="@/assets/img/ev-key_logo-white.png" contain></v-img>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text icon @click="drawer = !drawer" class="d-md-none">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <div class="d-none d-md-flex">
            <v-btn text @click="scrollToSection('solutionSection')">SOLUTION</v-btn>
            <v-btn text @click="scrollToSection('productsSection')">CHARGER</v-btn>
            <v-btn text @click="scrollToSection('plcSection')">PLC</v-btn>
            <v-btn text @click="scrollToSection('mepSection')">MEP</v-btn>
            <v-btn text @click="scrollToSection('pembSection')">PEMB</v-btn>
            <v-btn text @click="scrollToSection('factorySection')">FACTORY</v-btn>
            <v-btn text @click="scrollToSection('prvideoSection')">PR VIDEO</v-btn>
            <v-btn text @click="scrollToSection('historySection')">HISTORY</v-btn>
            <v-btn text @click="scrollToSection('contactSection')">CONTACT</v-btn>
          </div>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app temporary right color="grey darken-4" class="d-md-none">
          <v-list>
            <v-list-item style="position: relative;" @click="drawer = !drawer">
              <v-btn text icon class="white--text" style="position: absolute; top: 0; right: 25px;">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item @click="navigateAndClose('solutionSection')">
              <v-list-item-content class="white--text">SOLUTION</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('productsSection')">
              <v-list-item-content class="white--text">CHARGER</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('plcSection')">
              <v-list-item-content class="white--text">PLC</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('mepSection')">
              <v-list-item-content class="white--text">MEP</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('pembSection')">
              <v-list-item-content class="white--text">PEMB</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('MfactorySection')">
              <v-list-item-content class="white--text">FACTORY</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('prvideoSection')">
              <v-list-item-content class="white--text">PR VIDEO</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('MhistorySection')">
              <v-list-item-content class="white--text">HISTORY</v-list-item-content>
            </v-list-item>
            <v-list-item @click="navigateAndClose('contactSection')">
              <v-list-item-content class="white--text">CONTACT</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>



        <div class="video-container">
          <div class="video-wrap">
            <iframe :src="currentVideoSrc" frameborder="0" style="pointer-events: none; object-fit: cover;" width="90%"
              height="100%" allow="autoplay; encrypted-media" allowfullscreen class="tmp"></iframe>
            <!-- <div class="video-overlay"></div> -->
            <div class="text-overlay">
              <h1 class="main-title">Creating a better world with convenient charging</h1>
              <p class="subtitle">Meet EV-KEY convenient and sustainable EV charging</p>
            </div>
          </div>
        </div>
        <v-container class="black--text solution-section"
          style="background-color: #000; margin-top: 50px; margin-bottom: 150px; width: 100%;" ref="solutionSection">
          <v-row class="mt-6 mb-6">
            <v-col>
              <h1 class="solution-title2">
                <span class="desktop-solution">SOLUTION & SERVICES</span>

              </h1>
              <h1 class="solution-title">
                <span class="mobile-solution">SOLUTION<br>&<br>SERVICES</span>
              </h1>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="mb-6">
            <p class="white--text d-flex justify-center text-center contect-text">
              We provide the most effective customized solutions and services to <br> individuals and companies with
              business know-how accumulated since <br> our initial entry into the EV charger market.
            </p>
          </v-row>
          <v-row class="solution-img" justify="center" align="center">
            <v-img src="@/assets/img/solution.png" eager lazy-src="@/assets/img/solution.png"></v-img>
          </v-row>


          <v-row class="mb-6 mt-6 d-flex">
            <div>
              <h1 class="contect-text2">
                Solve complex EV charger and charging station<br>
                construction and management<br>
                all at once at EV-KEY.
              </h1>
            </div>
          </v-row>

          <v-row>
            <v-col cols="12" md="4" class="pa-12 border-box">
              <!-- <v-icon size="55" color="#005aff" class="mb-10 ml-5">mdi-ev-station</v-icon> -->
              <v-icon class="solution-icon">mdi-ev-station</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">01. Construction of electric <br> vehicle charger
                and<br>
                charging
                station
              </h2>
              <p class="white--text mb-12 ml-5" style="font-size: 1em">
                Before installing an electric vehicle charger, we conduct a site inspection to determine whether it is
                possible to install an electric vehicle charger. We carry out the most optimized design for the
                on-site environment, and you can receive estimates for construction and installation.
                We complete KEPCO rental work, underground construction, installation of electric vehicle chargers,
                and even operation testing.
              </p>
            </v-col>

            <v-col cols="12" md="4" class="pa-10 border-box">
              <v-icon class="solution-icon">mdi-monitor</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">CSMS charging station <br> control system
              </h2>
              <p class="white--text mb-12 ml-5" style="font-size: 1em">
                The charging station control system provides customers with more intuitive status information when
                operating multiple electric vehicle chargers and charging stations. Status information can be easily
                checked through real-time electric vehicle charging amount and charging status and daily cumulative
                amount graph, and information on members who used the charger can be checked.
              </p>
            </v-col>

            <v-col cols="12" md="4" class="pa-12 border-box">
              <v-icon class="solution-icon">mdi-web</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">03. OCPP 1.6 / 2.0.1 support
              </h2>
              <p class="white--text mb-12 ml-5" style="font-size: 1em">
                OCPP is a charger operation and management protocol created by stakeholders such as charging
                operators, charger manufacturers, automobile manufacturers, power suppliers, and protocol experts.
                Starting with
                OCPP 1.0, it has developed to OCPP 2.0.1 and is flexibly responding to the latest technology and
                market requirements.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4" class="pa-12 border-box">
              <v-icon class="solution-icon">mdi-server-outline</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">04. Intelligent groupware
              </h2>
              <p class="white--text mb-12 ml-5" style="font-size: 1em">
                By implementing the billing server (MS), web server (WS), and device server (DS) as groupware, complex
                functions can be set up and managed more intuitively and simply.
                This means that it is possible to immediately respond to issues for each server that were previously
                difficult to manage.
              </p>
            </v-col>

            <v-col cols="12" md="4" class="pa-12 border-box">
              <v-icon class="solution-icon">mdi-battery-charging</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">05. Simultaneous charging and sequential charging
              </h2>
              <p class="white--text mb-12 ml-5" style="font-size: 1em">
                Places where there is a lot of vehicle charging can be built as charging stations with simultaneous
                and sequential charging functions. This can be applied to places where there is a shortage of chargers
                or
                charging power compared to vehicles that need charging, creating an environment where multiple
                vehicles can be charged.
              </p>
            </v-col>

            <v-col cols="12" md="4" class="pa-12 border-box">
              <v-icon class="solution-icon">mdi-tools</v-icon>
              <h2 class="white--text mb-6 ml-5 solution-sub-title">06. Maintenance (A/S)
              </h2>
              <p class=" white--text mb-12 ml-5" style="font-size: 1em">
                Charger maintenance is carried out through 34 A/S centers in 10 regions1, and in the event of an
                emergency complaint, a quick on-site visit and troubleshooting within 24 hours is provided to normalize
                the charger. In addition, the application of remote diagnosis and repair technology (POTA) enables
                quick detection and action of faults.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="productsSection" class="mb-12 px-0">
          <v-row class="mt-12 justify-center mx-0 light-background">
            <v-col cols="12">
              <h1 class="white--text charger-title">CHARGER</h1>
              <v-row class="mt-2 justify-center mx-0">
                <v-col v-for="(product, index) in products" :key="index" cols="12" md="6" class="pa-0">
                  <v-card style="background-color: black; color: white; margin: 0;">
                    <v-carousel hide-delimiters>
                      <v-carousel-item v-for="(image, i) in product.images" :key="i" class="pa-3">
                        <v-img :src="image" eager contain class="carousel-image" lazy-src="image"></v-img>
                      </v-carousel-item>
                    </v-carousel>
                    <v-card-title class="justify-center mt-12">{{ product.name }}</v-card-title>
                    <v-card-text class="product-details" style="color: white; margin-top: 10px; padding: 0 16px;">
                      <ul v-if="product.name === 'EA003PCN4'">
                        <li>
                          <strong>Input :</strong> AC 120~240V Upto 16A
                        </li>
                        <li>
                          <strong>Output :</strong> AC 120~240V Upto 16A (max 3.84kW)
                        </li>
                        <li>
                          <strong>Protection Level :</strong> IP65
                        </li>
                        <li>
                          <strong>Operating Temp:</strong> -25 ~ 45℃
                        </li>
                        <li>
                          <strong>UI :</strong> LED Indicator, LCD Screen
                        </li>
                        <li>
                          <strong>Plug/Cable Length :</strong> SAE J1772 Type 1 with 25ft (7.5m) Cable
                        </li>
                      </ul>
                      <ul v-else-if="product.name === 'KL4322-CC ( 22kW )'">
                        <li>
                          <strong>Type:</strong> Wall Mount ( Stand option )
                        </li>
                        <li><strong>Channel:</strong> 2</li>
                        <li>
                          <strong>Input:</strong> Single-Phase AC 200~240V, 50A(11kW), 50/60Hz x 2 port
                        </li>
                        <li><strong>Output:</strong> 220V/50A x 2 ( 22kW )</li>
                        <li>
                          <strong>Environment Temperature:</strong> -23 ~ +55°C
                        </li>
                        <li><strong>Humidity:</strong> 5~95%</li>
                        <li>
                          <strong>Display:</strong> Two Channel 7-Segment LEDs
                        </li>
                        <li>
                          <strong>Authentication:</strong> PnC, QR Code, Mobile App, Membership Card
                        </li>
                        <li>
                          <strong>Protection Category:</strong> Outdoor, NEMA 3R
                        </li>
                        <li>
                          <strong>Dimension (WxHxD):</strong> 300(W) x 174(D) x 365(H) mm
                        </li>
                        <li><strong>Weight:</strong> 13 Kg</li>
                        <li>
                          <strong>Approval:</strong> UL Certification completed : Oct. 30
                        </li>
                        <li>
                          <strong>Connector:</strong> Two type-1 ( 7m Cable )
                        </li>
                        <li><strong>Features:</strong></li>
                        <ul>
                          <li>Highly compatible with all electric vehicles</li>
                          <li>
                            Charging progress information display ( Current, Power amount, SoC )
                          </li>
                          <li>
                            Fire safe function, 95% overcharging limit ( Patents pending )
                          </li>
                          <li>
                            High durability, suitable for dusty or coastal areas
                          </li>
                          <li>1% power metering accuracy</li>
                        </ul>
                      </ul>
                      <ul v-else-if="product.name === 'KL51-300-CCH15 ( 300kW )'">
                        <li>
                          <strong>Cable Management:</strong> Electromotive
                        </li>
                        <li><strong>Channel:</strong> 2</li>
                        <li>
                          <strong>Input:</strong> Three-Phase AC 380~480V, 50/60Hz
                        </li>
                        <li>
                          <strong>Output Power:</strong> 300kW max. (150kW+150kW or 250kW+0kW)
                        </li>
                        <li>
                          <strong>DC:</strong> 1,000Vdc Max. 350Adc ( NACS ), 250Adc ( CCS1 )
                        </li>
                        <li><strong>Power Efficiency:</strong> 95%</li>
                        <li>
                          <strong>Environment Temperature:</strong> -23 ~ +55°C
                        </li>
                        <li><strong>Humidity:</strong> 5~95%</li>
                        <li>
                          <strong>Display:</strong> 15 inch touch color LCD
                        </li>
                        <li>
                          <strong>Authentication:</strong> PnC, QR Code, Mobile App, Membership Card
                        </li>
                        <li>
                          <strong>Protection Category:</strong> Outdoor, NEMA 3R
                        </li>
                        <li>
                          <strong>Dimension (WxHxD):</strong> 800 (W) × 750 (D) × 1,999 (H) mm
                        </li>
                        <li><strong>Weight:</strong> Approx. 590 kg</li>
                        <li>
                          <strong>Approval:</strong> UL Certification completed : Oct. 30
                        </li>
                        <li>
                          <strong>Connector:</strong> NACS + CCS1 (7m Cable)
                        </li>
                        <li><strong>Features:</strong></li>
                        <ul>
                          <li>Highly compatible with all electric vehicles</li>
                          <li>
                            Charging progress information display (Current, Power amount, SoC)
                          </li>
                          <li>
                            Fire safe function, 95% overcharging limit ( Patents pending )
                          </li>
                          <li>
                            High durability, suitable for dusty or coastal areas
                          </li>
                          <li>1% power metering accuracy</li>
                          <li>Salt water prevention filter</li>
                          <li>
                            US-patent electromotive cable management system for handicapped
                          </li>
                        </ul>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="mb-12 px-0">
          <v-row class="mt-12 justify-center mx-0">
            <v-col cols="12">
              <v-row class="mt-2 justify-center mx-0">
                <v-col v-for="(product, index) in products_2" :key="index" cols="12" md="6" class="pa-0">
                  <v-card style="background-color: black; color: white; margin: 0;">
                    <v-carousel hide-delimiters>
                      <v-carousel-item v-for="(image, i) in product.images" :key="i" class="pa-3">
                        <v-img :src="image" eager class="carousel-image" contain lazy-src="image"></v-img>
                      </v-carousel-item>
                    </v-carousel>
                    <v-card-title class="justify-center mt-12">{{ product.name }}</v-card-title>
                    <v-card-text class="product-details" style="color: white; margin-top: 10px; padding: 0 16px;">
                      <ul v-if="product.name === 'KL51-300-CCH15 ( 300kW )'">
                        <li>
                          <strong>Cable Management:</strong> Electromotive
                        </li>
                        <li><strong>Channel:</strong> 2</li>
                        <li>
                          <strong>Input:</strong> Three-Phase AC 380~480V, 50/60Hz
                        </li>
                        <li>
                          <strong>Output Power:</strong> 300kW max. (150kW+150kW or 250kW+0kW)
                        </li>
                        <li>
                          <strong>DC:</strong> 1,000Vdc Max. 350Adc ( NACS ), 250Adc ( CCS1 )
                        </li>
                        <li><strong>Power Efficiency:</strong> 95%</li>
                        <li>
                          <strong>Environment Temperature:</strong> -23 ~ +55°C
                        </li>
                        <li><strong>Humidity:</strong> 5~95%</li>
                        <li>
                          <strong>Display:</strong> 15 inch touch color LCD
                        </li>
                        <li>
                          <strong>Authentication:</strong> PnC, QR Code, Mobile App, Membership Card
                        </li>
                        <li>
                          <strong>Protection Category:</strong> Outdoor, NEMA 3R
                        </li>
                        <li>
                          <strong>Dimension (WxHxD):</strong>
                          800 (W) × 750 (D) × 1,999 (H) mm
                        </li>
                        <li><strong>Weight:</strong> Approx. 590 kg </li>
                        <li>
                          <strong>Approval:</strong> UL Certification completed : Oct. 30
                        </li>
                        <li>
                          <strong>Connector:</strong> NACS + CCS1 ( 7m Cable )
                        </li>
                        <li><strong>Features:</strong></li>
                        <ul>
                          <li>
                            Highly compatible with all electric vehicles
                          </li>
                          <li> Charging progress information display ( Current, Power amount, SoC ) </li>
                          <li> Fire safe function, 95% overcharging limit ( Patents pending ) </li>
                          <li> High durability, suitable for dusty or coastal areas </li>
                          <li> 1% power metering accuracy </li>
                          <li> Salt water prevention filter </li>
                          <li> US-patent electromotive cable management system for handicapped </li>
                        </ul>
                      </ul>
                      <ul v-else-if="product.name === 'KL51-200-CCO2S-E ( 200kW )'">
                        <li>
                          <strong>Cable Management:</strong> Electromotive
                        </li>
                        <li><strong>Channel:</strong> 2</li>
                        <li>
                          <strong>Input:</strong> Three-Phase AC 380V~480V 50/60Hz
                        </li>
                        <li><strong>Output Power:</strong> 200kW max. ( 100kW+100kW or 200kW+0kW )</li>
                        <li><strong>DC:</strong> 1,000Vdc Max. 350Adc </li>
                        <li><strong>Power Efficiency:</strong> 95%</li>
                        <li>
                          <strong>Environment Temperature:</strong> -23 ~ +55°C
                        </li>
                        <li><strong>Humidity:</strong> 5~95%</li>
                        <li>
                          <strong>Display:</strong> 15 inch touch color LCD
                        </li>
                        <li>
                          <strong>Authentication:</strong> PnC, QR Code, Mobile App, Membership Card
                        </li>
                        <li>
                          <strong>Protection Category:</strong> Outdoor, NEMA 3R
                        </li>
                        <li>
                          <strong>Dimension (WxHxD):</strong>
                          750 (W) × 800 (D) × 2,035(H) mm
                        </li>
                        <li><strong>Weight:</strong> Approx. 550 kg </li>
                        <li><strong>Connector:</strong> Two CCS1 ( 7m Cable ) </li>
                        <li><strong>Features:</strong></li>
                        <ul>
                          <li>
                            Highly compatible with all electric vehicles
                          </li>
                          <li> Charging progress information display ( Current, Power amount, SoC ) </li>
                          <li> Fire safe function, 95% overcharging limit ( Patents pending ) </li>
                          <li> High durability, suitable for dusty or coastal areas </li>
                          <li> 1% power metering accuracy </li>
                          <li> Salt water prevention filter </li>
                          <li> US-patent electromotive cable management system for handicapped </li>
                        </ul>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>


        <v-container ref="plcSection" class="plc-container">
          <v-row class="mt-12 mb-12">
            <v-col>
              <h1 class="white--text d-flex justify-center solution-title">PLC</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-col cols="12 mobile-plc" md="6" sm="4">
                <v-responsive aspect-ratio="16/9">
                  <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
                    <iframe
                      src="https://www.youtube.com/embed/bNHP5ZnkIcc?autoplay=1&loop=1&playlist=bNHP5ZnkIcc&controls=0&modestbranding=1&rel=0&disablekb=1"
                      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
                    </iframe>
                  </div>
                </v-responsive>
              </v-col>
              <v-col cols="12 desktop-plc">
                <v-responsive aspect-ratio="16/9">
                  <iframe width="100%" height="400"
                    src="https://www.youtube.com/embed/bNHP5ZnkIcc?autoplay=1&loop=1&playlist=bNHP5ZnkIcc&controls=0&modestbranding=1&rel=0&disablekb=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </v-responsive>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <div class="white--text plc-text">
                <h3>EV-KEY Co., Ltd</h3>
                <h3>Provides a safe charging environment without battery fire</h3>
                <p>EV-KEY charger technology prevents overcharging of the batteries of all electric
                  vehicles ( CCS, NACS ) in operation in Korea and provides a safe charging environment by ending
                  charging
                  at 95%.</p>
                <h3>Real-time SoC display to
                  show battery charge status</h3>
                <p>By displaying the battery charge status at all times, electric vehicle users can charge
                  with confidence,
                  and anyone can check the battery charge level.</p>
                <h3>Supports safe charging even in underground parking lots</h3>
                <p>The EV-KEY charger limits the battery charge amount for all existing electric
                  vehicles ( CCS, NACS ),
                  so both electric vehicle owners and non-users can install the charger in an underground
                  parking lot with peace of mind.
                </p>
                <h3>Eliminate the inconvenience of payment with PnC ( Plug in Charge )</h3>
                <p>The 2024 EV-KEY charger allows you to instantly charge your electric vehicle by
                  connecting it to the charger without the inconvenience of payment.</p>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="mepSection" style="margin-bottom: 200px;">
          <v-row class="mt-12">
            <v-col>
              <h1 class="white--text text-center solution-title">MEP</h1>
              <p class="white--text text-center mt-3">Mechanical, Electrical, and Plumbing</p>
            </v-col>
          </v-row>
          <v-row class="mt-12" style="background-color: black;">
            <v-col cols="12" md="6">
              <v-img src="@/assets/img/MEP_1.png" class="d-flex justify-center" eager lazy-src="@/assets/img/MEP_1.png"
                @click="openDialog(require('@/assets/img/MEP_1.png'))"></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img src="@/assets/img/MEP_2.png" class="d-flex justify-center" eager lazy-src="@/assets/img/MEP_2.png"
                @click="openDialog(require('@/assets/img/MEP_2.png'))"></v-img>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="pembSection" style="margin-bottom: 200px;">
          <v-row class="mt-12">
            <v-col>
              <h1 class="white--text text-center solution-title">PEMB</h1>
              <p class="white--text text-center mt-3">Pre-Engineered Metal Building<br>Passenger Bus Depot
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-12" style="background-color: black;">
            <v-col cols="12" md="6">
              <v-img src="@/assets/img/PEMB_1.png" class="d-flex justify-center" eager
                lazy-src="@/assets/img/PEMB_1.png" @click="openDialog(require('@/assets/img/PEMB_1.png'))"></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img src="@/assets/img/PEMB_2.png" class="d-flex justify-center" eager
                lazy-src="@/assets/img/PEMB_2.png" @click="openDialog(require('@/assets/img/PEMB_2.png'))"></v-img>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="factorySection" class="desktop-factory" style="margin-bottom: 200px;">
          <v-row class="mt-12 mb-12">
            <v-col>
              <h1 class="white--text d-flex justify-center">FACTORY</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-img src="@/assets/img/factory.jpg" eager lazy-src="@/assets/img/factory.jpg" style="height: 892px;" />
            </v-col>
            <v-col cols="2">
              <v-row>
                <v-col cols="12">
                  <v-img src="@/assets/img/Product Dev Room1.jpg" eager lazy-src="@/assets/img/Product Dev Room1.jpg" />
                </v-col>
                <v-col cols="12">
                  <v-img src="@/assets/img/Product Dev Room3.jpg" eager lazy-src="@/assets/img/Product Dev Room3.jpg" />
                </v-col>
                <v-col cols="12">
                  <v-img src="@/assets/img/Product Dev Room8.jpg" eager lazy-src="@/assets/img/Product Dev Room8.jpg" />
                </v-col>
                <v-col cols="12">
                  <v-img src="@/assets/img/Product Dev Room13.jpg" eager
                    lazy-src="@/assets/img/Product Dev Room13.jpg" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="white--text">
              <v-row class="ml-6">
                <v-col cols="12">
                  <h2>Rapid and slow charger production plant</h2>
                </v-col>
                <v-divider class="mt-4 white-divider"></v-divider>
                <v-col cols="12">
                  <h3>ISO 45001 certification | Safety and health management system</h3>
                  <p>EV-KEY manufacturing plant complies with international standards that stipulate requirements
                    for systematically managing the safety and health of the organization by predicting and preventing
                    various risks that may occur in the workplace.</p>
                </v-col>
                <v-divider class="mt-4 white-divider"></v-divider>
                <v-col cols="12">
                  <h3>ISO 9001 certification | Quality Management System</h3>
                  <p>EV-KEY products are based on an international standard quality management system in which all
                    members of the organization participate in management activities such as planning, design,
                    purchasing, production, delivery, and service through customer requirements, customer
                    satisfaction,
                    and quality assurance. Comply with.</p>
                </v-col>
                <v-divider class="mt-4 white-divider"></v-divider>
                <v-col cols="12">
                  <h3>Electric vehicle charger manufacturer with the widest selection of products</h3>
                  <p>EV-KEY has manufactured a variety of electric vehicle chargers that meet customer needs based
                    on
                    the production technology it has accumulated since 2014. 3.3kW, 7kW, 11kW, 22kW slow chargers,
                    30kW,
                    50kW, 60kW, 100kW, 120kW, 200kW, 240kW fast chargers, and even a 350kW ultra-fast charger are
                    produced directly at Clean Elex's factory. There are over 40 types of chargers produced and
                    shipped
                    at the request of customers, which gives us pride in being the company with the widest selection
                    of
                    electric vehicle charger products both domestically and internationally.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="white--text">
              <h3>Domestic and overseas product certification</h3>
              <p>
                Products delivered domestically are provided with Q Mark quality certification, KC safety
                certification, and high-efficiency equipment certification, and we are laying the foundation for
                becoming a global electric vehicle charger manufacturer by undergoing Japanese JARI certification
                and U.S. UL certification.
              </p>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_1.jpg" eager lazy-src="@/assets/img/800600_1.jpg" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_2.jpg" eager lazy-src="@/assets/img/800600_2.jpg" />
            </v-col>
            <v-col cols="12">
              <v-img src="@/assets/img/1600600.jpg" eager lazy-src="@/assets/img/1600600.jpg" contain
                style="height: 0;  overflow: hidden;" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="4">
              <v-img src="@/assets/img/800600_3.jpg" eager lazy-src="@/assets/img/800600_3.jpg" />
            </v-col>
            <v-col cols="4">
              <v-img src="@/assets/img/800600_4.jpg" eager lazy-src="@/assets/img/800600_4.jpg" />
            </v-col>
            <v-col cols="4">
              <v-img src="@/assets/img/800600_5.jpg" eager lazy-src="@/assets/img/800600_5.jpg" />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="4">
              <v-img src="@/assets/img/800600_6.jpg" eager lazy-src="@/assets/img/800600_6.jpg" />
            </v-col>
            <v-col cols="4">
              <v-img src="@/assets/img/800600_7.jpg" eager lazy-src="@/assets/img/800600_7.jpg" />
            </v-col>
            <v-col cols="4">
              <v-img src="@/assets/img/800600_8.jpg" eager lazy-src="@/assets/img/800600_8.jpg" />
            </v-col>
          </v-row>
        </v-container>




        <v-container ref="MfactorySection" class="mobile-factory" style="margin-bottom: 200px;">
          <v-row class="mobile-fac">
            <v-col>
              <h1 class="white--text d-flex justify-center solution-title">FACTORY</h1>
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-1">
            <v-col cols="12">
              <v-img src="@/assets/img/factory.jpg" eager lazy-src="@/assets/img/factory.jpg" style="height: 100%;"
                @click="openDialog(require('@/assets/img/factory.jpg'))" />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="6" md="3">
                  <v-img src="@/assets/img/Product Dev Room1.jpg" eager lazy-src="@/assets/img/Product Dev Room1.jpg"
                    @click="openDialog(require('@/assets/img/Product Dev Room1.jpg'))" />
                </v-col>
                <v-col cols="6" md="3">
                  <v-img src="@/assets/img/Product Dev Room3.jpg" eager lazy-src="@/assets/img/Product Dev Room3.jpg"
                    @click="openDialog(require('@/assets/img/Product Dev Room3.jpg'))" />
                </v-col>
                <v-col cols="6" md="3">
                  <v-img src="@/assets/img/Product Dev Room8.jpg" eager lazy-src="@/assets/img/Product Dev Room8.jpg"
                    @click="openDialog(require('@/assets/img/Product Dev Room8.jpg'))" />
                </v-col>
                <v-col cols="6" md="3">
                  <v-img src="@/assets/img/Product Dev Room13.jpg" eager lazy-src="@/assets/img/Product Dev Room13.jpg"
                    @click="openDialog(require('@/assets/img/Product Dev Room13.jpg'))" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="ml-1 mr-1">
            <v-col cols="12" class="white--text">
              <h3>Domestic and overseas product certification</h3>
              <p>
                Products delivered domestically are provided with Q Mark quality certification, KC safety
                certification, and high-efficiency equipment certification, and we are laying the foundation for
                becoming a global electric vehicle charger manufacturer by undergoing Japanese JARI certification
                and U.S. UL certification.
              </p>
            </v-col>
          </v-row>

          <v-row justify="center" class="ml-1 mr-1">
            <v-col cols="6">
              <v-img src="@/assets/img/800600_1.jpg" eager lazy-src="@/assets/img/800600_1.jpg"
                @click="openDialog(require('@/assets/img/800600_1.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_3.jpg" eager lazy-src="@/assets/img/800600_3.jpg"
                @click="openDialog(require('@/assets/img/800600_3.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_4.jpg" eager lazy-src="@/assets/img/800600_4.jpg"
                @click="openDialog(require('@/assets/img/800600_4.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_2.jpg" eager lazy-src="@/assets/img/800600_2.jpg"
                @click="openDialog(require('@/assets/img/800600_2.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_5.jpg" eager lazy-src="@/assets/img/800600_5.jpg"
                @click="openDialog(require('@/assets/img/800600_5.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_6.jpg" eager lazy-src="@/assets/img/800600_6.jpg"
                @click="openDialog(require('@/assets/img/800600_6.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_7.jpg" eager lazy-src="@/assets/img/800600_7.jpg"
                @click="openDialog(require('@/assets/img/800600_7.jpg'))" />
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/img/800600_8.jpg" eager lazy-src="@/assets/img/800600_8.jpg"
                @click="openDialog(require('@/assets/img/800600_8.jpg'))" />

            </v-col>
          </v-row>

          <v-dialog v-model="Imgdialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="dialog-card">
              <v-btn icon @click="Imgdialog = false" class="close-btn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-img :src="dialogImage" aspect-ratio="1.5" eager class="dialog-img" />
            </v-card>
          </v-dialog>
        </v-container>

        <v-container ref="prvideoSection" style="margin-top: 150px; margin-bottom: 200px;">
          <v-row class="mt-12 mb-12">
            <v-col>
              <h1 class="white--text justify-center solution-title">PR VIDEO</h1>
            </v-col>
          </v-row>
          <v-row class="mt-12">
            <v-col v-for="(video, index) in prvideo" :key="index" cols="12" md="3">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 12 : 2" class="pa-3" @click="selectVideo(video)"
                  style="background-color: black; position: relative;">
                  <div style="position: relative;">
                    <img :src="thumbnails[index]" style="width: 100%; height: 200px; object-fit: cover;"
                      aspect-ratio="1.2">
                    <div :class="{ 'overlay': true, 'darken': !hover }"></div>
                    <v-icon class="white--text play-icon" v-if="!hover">mdi-play-circle-outline</v-icon>
                  </div>
                  <v-card-title class="white--text justify-center">{{ video.title }}</v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" width="1800">
            <v-card style="background-color: black;">
              <v-card-title class="headline white--text d-flex justify-space-between">
                {{ selectedVideo.title }}
                <v-btn icon @click="closeDialog">
                  <v-icon class="white--text">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <video ref="videoPlayer" width="100%" controls autoplay>
                  <source :src="selectedVideo.file" type="video/mp4">
                </video>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
        <v-container ref="historySection" class="desktop-history" style="margin-bottom: 200px;">
          <v-row class="mt-12">
            <v-col>
              <h1 class="white--text d-flex justify-center">History</h1>
              <v-col></v-col>
              <v-timeline class="mt-12">
                <v-timeline-item v-for="(event, index) in history" :key="index" color="blue-grey darken-4"
                  icon="mdi-circle-medium" class="timeline-item">
                  <template v-slot:opposite>
                    <v-card class="timeline-card light-background">
                      <v-card-title class="white--text">{{
                        event.year
                        }}</v-card-title>
                      <v-card-text>
                        <ul>
                          <li v-for="(item, itemIndex) in event.events" :key="itemIndex" class="white--text"
                            style="font-size: 16px">
                            {{ item }}
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </template>
                  <v-card v-if="hasImage(event.year)" class="timeline-image-card">
                    <v-img :src="getImageSrc(event.year)" class="timeline-image" eager
                      lazy-src="getImageSrc(event.year)"></v-img>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="MhistorySection" class="mobile-history" style="margin-top: 200px;">
          <v-row class="mobile-his">
            <v-col>
              <h1 class="white--text d-flex justify-center history-title">History</h1>
              <v-col></v-col>
              <v-card-text class="py-0">
                <v-timeline dense>
                  <v-slide-x-reverse-transition group hide-on-leave>
                    <v-timeline-item v-for="(event, index) in history" :key="index" color="blue-grey darken-4" small
                      icon="mdi-circle-medium" class="timeline-item">
                      <v-card-title class="white--text history-text">{{
                        event.year
                        }}</v-card-title>
                      <v-card-text>
                        <ui v-if="hasImage(event.year)">
                          <v-img :src="getImageSrc(event.year)" lazy-src="getImageSrc(event.year)" class="mb-3" eager
                            @click="openDialog(getImageSrc(event.year))" />
                        </ui>
                        <ul>
                          <li v-for="(item, itemIndex) in event.events" :key="itemIndex" class="white--text">
                            {{ item }}
                          </li>
                        </ul>
                      </v-card-text>
                    </v-timeline-item>
                  </v-slide-x-reverse-transition>
                </v-timeline>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>

        <v-container ref="contactSection">
          <v-row>
            <v-col>
              <h1 class=" white--text d-flex justify-center contect-title">CONTACT</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <strong class="white--text contact-text">name</strong>
              <v-text-field dense solo hide-details class="mt-3" v-model="form.name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <strong class="white--text contact-text">phone number</strong>
              <v-text-field dense solo hide-details class="mt-3" v-model="form.contact"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <strong class="white--text contact-text">email</strong>
              <v-text-field dense solo hide-details class="mt-3" v-model="form.email"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <strong class="white--text contact-text">corporate name</strong>
              <v-text-field dense solo hide-details class="mt-3" v-model="form.company"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <strong class="white--text contact-text">inquiry</strong>
              <v-textarea dense solo hide-details class="mt-3" v-model="form.message"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-card class="pa-3" color="black" dark>
                <v-row>
                  <v-col cols="12">
                    <!-- <v-checkbox v-model="agree"
                        label="I agree with the purpose of collecting and using personal information."
                        class="white--text contact-text2"></v-checkbox> -->
                    <v-checkbox v-model="agree" class="white--text contact-text2">
                      <template v-slot:label>
                        <span class="contact-text2">I agree with the purpose of collecting and using personal
                          information.</span>
                      </template>
                    </v-checkbox>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div style="max-height: 200px; overflow-y: auto;">
                      <p class="white--text contact-text2">
                        ▶ Purpose of Collection and Use of Personal Information<br>
                        - test<br>
                        <br>
                        ▶ Items of personal information to be collected<br>
                        - test
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-6">
            <v-btn @click="sendEmail" color="blue-grey darken-3" class="white--text" :disabled="!agree"
              :style="{ opacity: agree ? 1 : 0.5, width: '200px' }">
              Send Email
            </v-btn>
          </v-row>
        </v-container>
        <v-row class="footer-container d-flex justify-center mt-10">
          <v-col class="left-col text-center d-flex flex-column align-center">
            <span class="footer-title">EV-KEY LLC.</span>
            <span class="footer-content">(c) 2024 EV-KEY LLC ALL Rights Reserved.</span>
            <span class="footer-content">The information furnished by Klinelex is believed to be accurate and
              reliable.<br>Klinelex reserves
              the right to make changes in specifications at any time without notice.</span>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <!-- <div class="scroll-to-top">
      <v-btn fab dark small color="grey darken-4" @click="scrollToTop">
        <v-icon color="white">mdi-arrow-up</v-icon>
      </v-btn>
    </div> -->
  </v-app>
</template>
<script>
import axios from 'axios';

/* global YT */

export default {
  name: "HomePage",
  data() {
    return {
      drawer: false,
      form: {
        name: '',
        contact: '',
        email: '',
        company: '',
        message: ''
      },
      agree: false,
      testUrl: `https://www.youtube.com/embed/bNHP5ZnkIcc`,
      dialog: false,
      Imgdialog: false,
      dialogImage: '',
      selectedVideo: {},
      prvideo: [
        { file: require("@/assets/video/1.mp4"), title: "Drone Demo" },
        { file: require("@/assets/video/2.mp4"), title: "PLC & PnC Promotion" },
        { file: require("@/assets/video/3.mp4"), title: "High-efficiency Fast Charger" },
        { file: require("@/assets/video/4.mp4"), title: "Electromotive Cable Management" }
      ],
      thumbnails: [
        require("@/assets/img/thum_1.png"),
        require("@/assets/img/thum_2.png"),
        require("@/assets/img/thum_3.png"),
        require("@/assets/img/thum_4.png")
      ],
      products: [
        {
          name: "EA003PCN4",
          images: [
            require("@/assets/img/EA003PCN4.png"),
          ],
        },
        {
          name: "KL4322-CC ( 22kW )",
          images: [
            require("@/assets/img/22kW_AC_정면.png"),
            require("@/assets/img/22kW_AC_좌측.png"),
            require("@/assets/img/22kW_AC_우측.png"),
          ],
        },
      ],
      products_2: [
        {
          name: "KL51-300-CCH15 ( 300kW )",
          images: [
            require("@/assets/img/300kw_정면.png"),
            require("@/assets/img/300kw_좌측.png"),
            require("@/assets/img/300kw_SL.png"),
            require("@/assets/img/300kw_SR.png"),
            require("@/assets/img/300kw_우측.png"),
          ],
        },
        {
          name: "KL51-200-CCO2S-E ( 200kW )",
          images: [
            require("@/assets/img/200kW_정면.png"),
            require("@/assets/img/200kW_좌측.png"),
            require("@/assets/img/200kW_우측.png"),
          ],
        },
      ],
      history: [
        {
          year: 2023,
          events: [
            "September Coupang Logistics Camp electric vehicle charger supplier selection and delivery",
            "July Contracted to supply slow and rapid chargers to Japan's Enzamine Power",
            "June Started operation of 4MW smart charging station at the southern public garage in Suwon City",
            "May SG Life Safety Annual Charger Supplier Selection and Delivery",
            "April Selected and delivered charger supplier to Hyundai Plaspo (Hyundai Oil Bank)",
            "March GS Connect slow charger supply contract",
            "March Hanwha Solution slow charger supply contract",
            "February KEPCO trusted item supply partner",
            "January Certification of high-efficiency equipment for rapid chargers (50kW, 100kW)",
          ],
        },
        {
          year: 2022,
          events: [
            "December Selected by the Ministry of Environment as a business to build a slow charging infrastructure",
            "December Started operation of 4MW smart charging station at Hwaseong Anyeong-dong bus garage",
            "November 10th anniversary celebration and declaration of leap forward as a unicorn company",
            "October Expansion and relocation to Hanwoong Building, Gunpo City (1st to 6th floors)",
            "August $2 million technology transfer contract for mass export of chargers to Malaysia",
            "July Selected as a public charging business by the Ministry of Environment and installed more than 4,000 units in total",
            "June Supply chargers to major public charging operators (GS Connect, Red ENG, Hanwha Energy, LG Hello Vision, etc.)",
            "June Acquired quality certification Q mark designation (KTC)",
            "April Promoted as the host company for the Korea Institute of Energy Technology Evaluation and Planning project “Driver-customized smart charging service and high-efficiency fast charging system” Delivered 150 200kW fast chargers to the Ministry of Environment Passed the harsh environment test by KEPCO for 3 charger models (50, 100, 200kW)",
          ],
        },
        {
          year: 2021,
          events: [
            "Selected as KEPCO 100kW rapid reliable item supply partner and delivered to 400 units",
            "Performed OCPP conversion consulting service for Korea Environment Corporation",
            "Korea Institute of Energy Technology Evaluation and Planning",
            "“Two-way information-based apartment complex smart charging system/service”",
          ],
        },
        {
          year: 2020,
          events: [
            "Selected as a supply partner for KEPCO 50kW rapid new product and delivered to 200 units",
            "Signed agreement with LH to build smart charging station for apartment complex and commenced construction",
          ],
        },
        {
          year: 2019,
          events: [
            "Highest compatibility confirmed in fast charger Testcal hosted by KERI-SG Association",
            "Supplied KEPCO rapid charger for transportation vulnerable users",
          ],
        },
        {
          year: 2018,
          events: [
            "Selected as a public charging business by the Ministry of Environment and entering the charging service business",
          ],
        },
        {
          year: 2017,
          events: [
            "Selected as GENTEL consortium as a public charging business by the Ministry of Environment (exclusive charger supplier)",
          ],
        },
        {
          year: 2016,
          events: ["Supplied slow charger to KEPCO"],
        },
        {
          year: 2015,
          events: [
            "Moved into the IT Valley office building in Indeokwon, Uiwang-si",
          ],
        },
        {
          year: 2014,
          events: ["Opened at Anyang University Startup Incubation Center"],
        },
      ],
      videos: [
        "02n_hUWT0Ag",
        "53ZDSVESx-c",
        "0qt9hCExLUU"
      ],
      currentVideoIndex: 0,
      player: null,
      videoInterval: null,
    };
  },
  computed: {
    currentVideoSrc() {
      return `https://www.youtube.com/embed/${this.videos[this.currentVideoIndex]}?autoplay=1&mute=1&enablejsapi=1`;
    }
  },
  beforeDestroy() {
    clearInterval(this.videoInterval);
    document.body.style.overflow = 'auto';
  },
  methods: {
    openDialog(image) {
      console.log('image', image)
      this.dialogImage = image;
      this.Imgdialog = true;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async sendEmail() {
      console.log('this.form', this.form);
      try {
        const response = await this.$axios.post('https://ev-key.net/send-email', this.form, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data) {
          alert('The email has been sent successfully.');
        }
      } catch (error) {
        console.error('Failed to send email', error);
        alert('There was an error sending the email. Please try again later.');
      }
    },


    closeDialog() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.currentTime = 0;
      }
      this.dialog = false;
    },
    hasImage(year) {
      const yearsWithImages = [2023, 2022];
      return yearsWithImages.includes(year);
    },
    getImageSrc(year) {
      const imageSrcs = {
        2023: require("@/assets/img/2023.jpg"),
        2022: require("@/assets/img/2022.jpg"),
      };
      return imageSrcs[year];
    },
    scrollToSection(section) {
      this.$refs[section].scrollIntoView({ behavior: "smooth" });
    },
    navigateAndClose(section) {
      this.scrollToSection(section);
      this.drawer = false;
    },
    selectVideo(video) {
      this.selectedVideo = video;
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.videoPlayer.load();
      });
    },
    loadYouTubeAPI() {
      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
    },
    onYouTubeIframeAPIReady() {
      this.player = new YT.Player(this.$el.querySelector('iframe'), {
        events: {
          'onStateChange': this.onPlayerStateChange
        }
      });
    },
    nextVideo() {
      this.fadeOutVideo();
      setTimeout(() => {
        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videos.length;
        this.updateVideo();
        this.fadeInVideo();
      }, 1000);
    },
    fadeOutVideo() {
      const iframe = this.$el.querySelector('iframe');
      iframe.style.transition = 'opacity 1.2s';
      iframe.style.opacity = 0;
    },
    fadeInVideo() {
      const iframe = this.$el.querySelector('iframe');
      iframe.style.transition = 'opacity 1.2s';
      iframe.style.opacity = 1;
    },
    updateVideo() {
      if (this.player) {
        this.player.loadVideoById(this.videos[this.currentVideoIndex]);
      }
    },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.ENDED) {
        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videos.length;
        this.updateVideo();
      }
    },
    getPlaceholder(image) {
      return image.replace(/\.png$/, "_placeholder.png");
    },

  },
  mounted() {
    document.body.style.overflow = 'hidden'
    this.loadYouTubeAPI();
    this.videoInterval = setInterval(this.nextVideo, 21000);
  },

};
</script>

<style scoped>
.solution-title2 {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-top: 20px;
}

.contect-title {
  font-size: 1.7rem;
  color: white;
  text-align: center;
  margin-top: 50px;
}

.contect-text {
  font-size: 2.1rem;
  color: white;
  text-align: center;
  margin-top: 50px;
}

.contect-text2 {
  font-size: 2.2rem;
  color: white;
  margin-top: 50px;
}

.home {
  background-color: black;
  padding-top: 0;
  width: 100%;
  /* Remove any padding at the top */
}

.solution-section {
  margin-top: 50px;
  margin-bottom: 150px;
  width: 100%;
  background-color: #000;
}


.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


.video-wrap {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

iframe.tmp {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%) scale(1.5);
  /* Scale the video to fill the container */
  transition: visibility 0s, opacity 0.5s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(0, 0, 0, 0.4);
}

.text-large {
  font-size: 48px;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.main-title {
  font-size: 4em;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subtitle {
  font-size: 1.5em;
  margin: 20px 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}


.cta-button {
  font-size: 1.2em;
  padding: 10px 20px;
}

.light-background {
  background-color: #000000;
  width: 100%;
}

.white--text {
  color: #ffffff !important;
}

/* ::v-deep .theme--light.v-timeline::before {
  background: white;
  margin-left: -34px;
} */
::v-deep .theme--light.v-timeline::before {
  background: white;
  margin-left: -6px;
}

.timeline-item {
  position: relative;
  margin-right: -20px;
  margin-left: -34px;
}

.timeline-icon {
  position: absolute;
  left: -20px;
  top: 20px;
}


/* .timeline-card {
  margin-left: 30px;
} */
.timeline-card {
  color: #ffffff;
  text-align: left;
}

.timeline-image {
  border-radius: 8px;
  /* margin-top: 10px; */
}

.footer-container {
  background-color: #000000a8;
  color: white;
  padding: 20px;
  width: 100%;
}

.left-col span {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.footer-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1rem !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.darken {
  background-color: rgba(0, 0, 0, 0.262);
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
}

.v-text-field,
.v-textarea {
  background-color: #FFFFFF;
  border: none;
}

.v-text-field::before,
.v-text-field::after,
.v-textarea::before,
.v-textarea::after {
  display: none;
}

/*
.inner-circle {
  width: 180px;
  height: 180px;
  margin: 10px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  padding: 20px;
} */
.inner-circle {
  width: 380px;
  height: 380px;
  margin: 10px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 20px;
}

.inner-circle-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.border-box {
  border: 1px solid white;
  margin-bottom: 20px;
}

.product-details {
  color: white;
  margin-top: 10px;
  padding: 0 16px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.product-details ul {
  padding-left: 0;
}

.product-details ul li {
  list-style: none;
}

.solution-text {
  font-size: 38px;
}

.solution-title {
  color: white;
  text-align: center;
}

.charger-title {
  color: white;
  text-align: center;
  margin-bottom: 120px;
}

.mobile-solution {
  display: none;
}

.mobile-plc {
  display: none;
}

.mobile-history {
  display: none;
}

.mobile-factory {
  display: none;
}

.solution-icon {
  font-size: 55px;
  color: #005aff;
  margin-bottom: 10px;
  margin-left: 5px;
}

.solution-sub-title {
  white-space: pre-line;
  font-size: 1.3em;
}

::v-deep .mdi-chevron-right::before {
  margin-left: 0px;
}

::v-deep .mdi-chevron-left::before {
  margin-right: 0px;
}

.carousel-image {
  height: 100%;
}

.plc-text {
  margin-top: 10px;
}

.plc-container {
  margin-top: 150px;
  margin-bottom: 200px;
}

.solution-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 165px;
  width: 80%;
  height: 80%;
}

.logo-img {
  height: 50px;
  width: 150px;
}

@media (max-width: 1080px) {
  ::v-deep .mdi-chevron-right::before {
    margin-left: 20px;
  }

  ::v-deep .mdi-chevron-left::before {
    margin-right: 20px;
  }

  ::v-deep .v-card-sheet.theme--light::before {
    display: none;
    color: #000000;
  }

  ::v-deep .theme--light.v-timeline::before {
    background: white;
    margin-left: -34px;
  }

  ::v-deep .v-card__title+.v-card__subtitle,
  .v-card__title+.v-card__text {
    padding-top: 0px;
    padding-left: 0px;
  }

  .timeline-item {
    margin-bottom: 10px;
  }

  .history-text {
    font-size: 1rem !important;
  }

  .v-card-text {
    padding: 5px;
  }

  .ui v-img {
    margin-bottom: 5px;
  }

  ul {
    margin-top: 5px;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: calc(60vh - 79px);
    overflow: hidden;
    margin-top: 0;
  }

  .product-details {
    color: white;
    margin-top: 10px;
    padding: 0 16px;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .contect-text2 {
    color: white;
    font-size: 22px;
    margin-top: 50px;
    padding-left: 45px;
  }

  .solution-img {
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  .solution-img v-img {
    width: 100%;
    height: auto;
  }

  /* .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: -20px;
  } */
  .history-title {
    white-space: pre-line;
    font-size: 1.7em;
    margin-bottom: 27 !important;
  }

  .footer-container {
    background-color: #000000a8;
    color: white;
    padding: 20px;
    width: 100%;
    margin-left: 2px
  }

  .carousel-image {
    width: 50% !important;
    margin: 0 auto;
    margin-top: 0px;
  }

  .solution-icon {
    font-size: 40px;
    color: #005aff;
    margin-bottom: 10px;
    margin-left: 11px;
  }



  .desktop-solution {
    display: none;
  }

  .mobile-solution {
    display: inline;
  }

  .desktop-plc {
    display: none;
  }

  .mobile-plc {
    display: inline;
  }

  .desktop-history {
    display: none;
  }

  .mobile-history {
    display: inline;
  }

  .mobile-his {
    margin-top: 30px;
    margin-bottom: 23px;
  }

  .desktop-factory {
    display: none;
  }

  .mobile-factory {
    display: inline;
  }

  .mobile-fac {
    margin-top: 29px;
    margin-bottom: 21px;
  }

  .logo-img {
    height: 60px !important;
    width: 100px !important;
  }

  .main-title {
    font-size: 1.23em !important;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 13pc;
  }

  .subtitle {
    font-size: 0.8em !important;
    margin: 20px 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .text-overlay {
    top: 23%;
    transform: translate(-50%, -40%);
  }

  iframe.tmp {
    transform: translate(-50%, -50%) scale(2);
  }

  .plc-text {
    font-size: 0.8em;
    margin-left: 5px;
    margin-right: 5px;
  }

  .solution-text {
    font-size: 0.63em;
  }

  .contect-text {
    font-size: 15px;
  }

  .circle-text {
    font-size: 0.6em;
  }

  .text-large {
    font-size: 0.9em;
    text-align: center;
    /* margin-left: 16px; */
  }

  .contact-text {
    font-size: 0.9em;
  }

  .contact-text2 {
    font-size: 0.8em;
  }

  .solution-title {
    white-space: pre-line;
    font-size: 1.7em;
    margin-bottom: 0 !important;
  }

  .charger-title {
    white-space: pre-line;
    font-size: 1.7em;
    margin-bottom: 0 !important;
  }

  .solution-sub-title {
    white-space: pre-line;
    font-size: 1.3em;
  }

  .solution-section {
    margin-top: -55 !important;
    margin-bottom: 150px;
    width: 100% !important;
  }

  .left-col span {
    font-size: 0.8rem;
  }

  .footer-title {
    font-size: 0.8rem !important;
  }

  .footer-content {
    font-size: 0.65rem !important;
  }

  .border-box {
    border: 0px solid white;
    margin-bottom: -38px;
  }


  .v-img {
    cursor: pointer;
  }

  .dialog-card {
    background-color: rgba(0, 0, 0, 0.9);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dialog-img {
    width: 100%;
    height: auto;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white !important;
    z-index: 1;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

body {
  overflow: hidden;
}
</style>