<template>
  <v-app class="white--text black--bg">
    <v-main>
      <v-container style="margin-bottom: 500px;">
        <v-row>
          <v-col cols="12 mt-3">
            <h1>EV-KEY Cookie Policy</h1>
            <h2 class="mt-7">What are cookies?</h2>
            <p>
              This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the
              information we collect using cookies and how that information is used, and how to manage the cookie
              settings.

              Cookies are small text files that are used to store small pieces of information. They are stored on your
              device when the website is loaded on your browser. These cookies help us make the website function
              properly, make it more secure, provide better user experience, and understand how the website performs and
              to analyze what works and where it needs improvement.
            </p>

            <h2 class="mt-7">How do we use cookies?</h2>
            <p>
              As most of the online services, our website uses first-party and third-party cookies for several purposes.
              First-party cookies are mostly necessary for the website to function the right way, and they do not
              collect any of your personally identifiable data.

              The third-party cookies used on our website are mainly for understanding how the website performs, how you
              interact with our website, keeping our services secure, providing advertisements that are relevant to you,
              and all in all providing you with a better and improved user experience and help speed up your future
              interactions with our website.
            </p>

            <!-- <h2>What type of cookies do we use and how?</h2>
            <h3>1) Strictly necessary cookies</h3>
            <p>
              Some cookies are essential for you to be able to experience the full functionality of our site. These
              cookies perform a variety of activities that are necessary for you to navigate the website and use its
              functionalities optimally.
            </p>
            <ul>
              <li>you to log-in to your IKEA account</li>
              <li>you to fill forms</li>
              <li>you to add products to your basket and checkout securely</li>
              <li>us to process your payment and order</li>
              <li>us to store your privacy preferences</li>
              <li>us to display the correct page for the device you are using</li>
            </ul>
            <p>
              Without these cookies, some parts of our site just won't work as they should. Strictly necessary cookies
              cannot be turned off from the cookie settings.
            </p> -->
            <h2 class="mt-7">Types of Cookies we use</h2>
            <h3>1) Necessary</h3>
            <p>
              Necessary cookies are required to enable the basic features of this site, such as providing secure log-in
              or adjusting your consent preferences. These cookies do not store any personally identifiable data.
            </p>
            <ul>
              <li>Ensuring the website loads correctly</li>
              <li>Allowing you to view images and videos</li>
              <li>Storing your preferences for website settings</li>
            </ul>
            <p>
              Without these cookies, some parts of our site just won't work as they should. Strictly necessary cookies
              cannot be turned off from the cookie settings.
            </p>

            <h3 class="mt-7">2) Performance cookies</h3>
            <p>
              These cookies tell us about how you use the site and they help us to make it better. For example, these
              cookies count the number of visitors to our website and see how visitors move around when they are using
              it. This helps us to improve the way our site works, for example, by ensuring that users find what they
              are looking for easily and that the loading time of the page is not too long. Analysing how you use our
              site also helps us to troubleshoot any problems and to monitor our own performance.
            </p>
            <p>
              Performance and analytics cookies can be turned off from the cookie settings.
            </p>
            <p>
              Examples of use:
            </p>
            <p>
              Google Analytics: These are performance cookies which used to track visits to our website and movement
              around the site. The data which is collected is anonymous and is used for benchmarking purposes to monitor
              the performance of our site and to measure user engagement. We share the anonymised Google Analytics data
              with selected third parties to assist us in understanding the effectiveness of our website and our
              marketing efforts.
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>_gid</li>
              <li>_gclxxxx</li>
              <li>_ga</li>
            </ul>

            <p>
              <strong>Optimizely:</strong> The Optimizely cookies allow us to test different versions of website pages
              to different users at the same time. It also helps us to track how users navigate around our website. We
              use this information to test new features and make the website easier to use.
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>optimizelyEndUserId</li>
              <li>optimizelyPPID</li>
              <li>optimizelyDomainTestCookie</li>
              <li>optimizelyRumLB</li>
            </ul>

            <p>
              <strong>ContentSquare:</strong> These are performance improver cookies which we use to observe user
              engagement and journey on our site. The data is used to improve your experience for example decrease the
              amount of clicks required during the shopping journey.
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>_cs_same_site</li>
              <li>_cs_id</li>
              <li>_cs_s</li>
              <li>_cs_c</li>
              <li>_cs_same_site</li>
            </ul>

            <p>
              <strong>Akamai:</strong> These are performance cookies which help us to ensure that content delivery on
              our site is in order and they ensure our servers are optimised so that the webpage loads as quickly as
              possible.
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>RT</li>
            </ul>

            <h3>3) Functionality cookies</h3>
            <p>
              These cookies allow our site to remember your preferences, helping you to customise your experience on our
              site.
            </p>
            <p>
              Functionality cookies can be turned off from the cookie settings. If you choose to turn off these cookies,
              some of the functionalities might either look a bit odd or not work at all.
            </p>

            <h3>4) Targeting cookies</h3>
            <p>
              These cookies are used to deliver advertised content which is relevant to you and they can be used to
              track the effectiveness of our ad campaigns on third party websites. These cookies are placed on our site
              by our third-party service providers and they may remember your web browsing activity and may be used to
              understand about your demographics, such as age and gender. The information these cookies process is about
              your actions and your interests so that adverts on other sites are more relevant to you. For example if
              you are searching for sofas you might later on be shown an ad or get sent a marketing email with an offer
              on sofas.
            </p>
            <p>
              We use the data collected via advertising cookies to making email marketing as relevant for you as
              possible. This could mean for example that if you have added a bed to your shopping cart, we send you a
              reminder to your email (if you have consented to email marketing) or if you have browsed certain products
              we send you an offer of similar products or products you might like.
            </p>
            <p>
              Some social media cookies are used for advertising. They can track your browser over the boundaries of
              EV-KEY to get a better idea of what your interests are. We also use social media cookies to market our
              products to you when you visit the social media platforms. Advertising and social media cookies can be
              turned off from the cookie settings. Should you choose to turn these cookies off, you will still see ads
              but they will be more generic.
            </p>

            <p>
              <strong>Adform:</strong> These are targeting cookies which are used to give us a view on our marketing
              activities and used for our servers used for advertising. Their main purpose is for real-time bidding to
              display advertising to targeted audiences.
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>uid</li>
              <li>C</li>
            </ul>


            <p>
              <strong>Salesforce:</strong> These are targeting cookies which allow us to use Salesforce data management
              platform to collect device information and behavioral data about how our websites and other digital
              touchpoints are used. The information is used to build audiences for online advertising. The aim is to
              make sure we advertise relevant products and services at the correct time. Audiences can be for example
              interest based (viewed kitchen products) or based on demography (age, gender).
            </p>
            <p>Used cookie names are below:</p>
            <ul>
              <li>_kuid_</li>
            </ul>

            <h3>How can you control the use of cookies?</h3>
            <p>
              We want you to be in control of your privacy. You can find a link to cookie settings from the bottom of
              each page. In the cookie settings menu you can choose the types of cookies you allow us to use. Please
              note that the settings are specific for each EV-KEY country meaning that you need to change the settings
              again if you visit another EV-KEY country website.
            </p>
            <p>
              You can also change your browser settings that are applicable for all websites you visit. Different
              browsers make different controls available to you. Generally, your browser will offer you the choice to
              accept, always refuse or delete cookies, or those from providers that website owners use i.e. third-party
              cookies, or those from specific websites. Each browser’s website should contain instructions on how you
              can do this.
            </p>
            <p>
              For further information please refer to: <a href=" https://www.aboutcookies.org/how-to-delete-cookies/"
                target="_blank">https://www.aboutcookies.org/how-to-delete-cookies/</a>
            </p>
            <p>
              If you block cookies on our website, you may be unable to access certain areas of our website and
              certain
              functions and pages will not work in the usual way.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style>
.black--bg {
  background-color: #000000 !important;
  font-size: 20px;
}

.white--text {
  color: #ffffff !important;
}
</style>
