<template>
  <div id="app">
    <router-view />
    <CookieConsent />
  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';
// import CookiePolicy from './components/CookiePolicy.vue';

export default {
  name: "App",
  metaInfo: {
    title: "EV_KEY",
    titleTemplate: "%s",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: 'description', content: 'ev-key', vmid: 'description' },
      { property: 'og:title', content: 'EV_KEY', vmid: 'og:title' },
      { property: 'og:description', content: 'ev-key', vmid: 'og:description' },
      { property: 'og:image', content: 'https://ev-key.net/favicon.ico', vmid: 'og:image' }
    ],
    link: [
      { rel: 'icon', href: '/favicon.ico' }
    ]
  },
  components: {
    CookieConsent
  },
};
</script>
