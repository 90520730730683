<template>
  <div v-if="visible" class="cookie-consent">
    <p>
      Why we use cookies and other tracking technologies? Our site enables script (e.g. cookies) that is able to read,
      store, and write information on your browser and in your device. The information processed by this script
      includes
      data relating to you which may include personal identifiers (e.g. IP address and session details) and browsing
      activity. We use this information for various purposes - e.g. to deliver content, maintain security, enable user
      choice, improve our sites, and for marketing purposes. You can reject all non-essential processing by choosing
      to
      accept only necessary cookies. To personalize your choice and learn more click here to adjust your preferences
      <router-link to="/cookie-policy">Read more about these cookies</router-link>
    </p>
    <div class="buttons">
      <button @click="showDialog = true">Customize</button>
      <button @click="rejectAllCookies">Reject All</button>
      <button @click="acceptAllCookies">Accept All</button>
    </div>

    <v-dialog v-model="showDialog" max-width="800px">
      <v-card>
        <v-card-title>
          Customize Consent Preferences
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab>Cookie Categories</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-expansion-panels>
                <p>We use cookies to help you navigate efficiently and perform certain functions. You will find detailed
                  information about all cookies under each consent category below. The cookies that are categorized as
                  "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of
                  the site. We also use third-party cookies that help us analyze how you use this website, store your
                  preferences, and provide the content and advertisements that are relevant to you. These cookies will
                  only be stored in your browser with your prior consent. You can choose to enable or disable some or
                  all
                  of these cookies but disabling some of them may affect your browsing experience.</p>
                <div>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <h3>
                      Strictly necessary cookies
                    </h3>
                    <v-switch v-model="switch1" disabled></v-switch>
                  </div>
                  <p>
                    These cookies are necessary for the website to function and cannot be switched off in our systems.
                    They are usually only set in response to actions made by you which amount to a request for services,
                    such as setting your privacy preferences, logging in or filling in forms. You can set your browser
                    to block or alert you about these cookies, but some parts of the site will not then work. These
                    cookies do not store any personally identifiable information.
                  </p>
                </div>
                <div>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <h3>
                      Performance cookies
                    </h3>
                    <v-switch v-model="switch2"></v-switch>
                  </div>
                  <p>
                    These cookies allow us to count visits and traffic sources, so we can measure and improve the
                    performance of our site. They help us know which pages are the most and least popular and see how
                    visitors move around the site. All information these cookies collect is aggregated and therefore
                    anonymous. If you do not allow these cookies, we will not know when you have visited our site.
                  </p>
                </div>
                <div>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <h3>Analytics</h3>
                    <v-switch v-model="switch3"></v-switch>
                  </div>
                  <p>
                    Analytical cookies are used to understand how visitors interact with the website. These cookies help
                    provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.
                  </p>
                </div>
                <div>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <h3>Performance</h3>
                    <v-switch v-model="switch4"></v-switch>
                  </div>
                  <p>
                    Performance cookies are used to understand and analyze the key performance indexes of the website
                    which helps in delivering a better user experience for the visitors.
                  </p>
                </div>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="savePreferences">Save My Preferences</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      switch1: true,
      switch2: false,
      switch3: false,
      switch4: false,
      visible: true,
      showDialog: false,
      tab: 0,
    };
  },
  methods: {
    acceptAllCookies() {
      document.cookie = "cookieConsent=true; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "performanceCookies=true; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "analyticsCookies=true; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "otherPerformanceCookies=true; path=/; max-age=" + 60 * 60 * 24 * 365;
      this.switch2 = true;
      this.switch3 = true;
      this.switch4 = true;
      this.visible = false;
    },
    rejectAllCookies() {
      document.cookie = "cookieConsent=false; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "performanceCookies=false; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "analyticsCookies=false; path=/; max-age=" + 60 * 60 * 24 * 365;
      document.cookie = "otherPerformanceCookies=false; path=/; max-age=" + 60 * 60 * 24 * 365;
      this.switch2 = false;
      this.switch3 = false;
      this.switch4 = false;
      this.visible = false;
    },
    checkCookieConsent() {
      const consentCookie = this.getCookie('cookieConsent');
      if (consentCookie === 'true') {
        this.switch2 = this.getCookie('performanceCookies') === 'true';
        this.switch3 = this.getCookie('analyticsCookies') === 'true';
        this.switch4 = this.getCookie('otherPerformanceCookies') === 'true';
        this.visible = false;
      } else {
        this.switch2 = false;
        this.switch3 = false;
        this.switch4 = false;
        this.visible = true;
      }
    },
    savePreferences() {
      document.cookie = `performanceCookies=${this.switch2}; path=/; max-age=` + 60 * 60 * 24 * 365;
      document.cookie = `analyticsCookies=${this.switch3}; path=/; max-age=` + 60 * 60 * 24 * 365;
      document.cookie = `otherPerformanceCookies=${this.switch4}; path=/; max-age=` + 60 * 60 * 24 * 365;
      this.showDialog = false;
      this.visible = false;
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  mounted() {
    this.checkCookieConsent();
  }
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(54, 54, 54, 0.925);
  color: white;
  padding: 20px 40px;
  z-index: 1000;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

.cookie-consent a {
  color: #ffb5b5;
  text-decoration: underline;
}

.cookie-consent .buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.cookie-consent button {
  background-color: #003a73c1;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 3px;
}

.cookie-consent button:hover {
  background-color: #1002d4;
}

@media (max-width:1080px) {
  .cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(54, 54, 54, 0.925);
    color: white;
    padding: 20px 40px;
    z-index: 1000;
    font-size: 15px;
    text-align: center;
    box-sizing: border-box;
  }

  .cookie-consent a {
    color: #ffb5b5;
    text-decoration: underline;
  }

  .cookie-consent .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }

  .cookie-consent button {
    background-color: #003a73c1;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px;
  }

  .cookie-consent button:hover {
    background-color: #1002d4;
  }
}
</style>
